<template>
  <div class="w-full">
    <div class="signup-form__logo h-36 mx-auto mb-6 bg-contain bg-no-repeat bg-center" />
    <ui-transition>
      <form
        v-if="ready"
        @submit.prevent="signup"
      >
        <div class="mb-6 text-center py-3 border-t border-b border-cool-gray-800">
          <p class="text-xs text-main">
            ようこそ！
          </p>
          <p class="text-main">
            <span class="inline-block text-lg font-extrabold">{{ realname }}</span>
            <span class="inline-block ml-1">様</span>
          </p>
        </div>
        <ui-input
          v-model="displayName"
          class="mb-1"
          label="表示名（アプリ内の名前表示に使われます）"
        />
        <p
          class="text-xxs text-red-300 text-opacity-75 mb-6 ml-1"
          v-text="displayNameError"
        />
        <ui-input
          v-model="username"
          class="mb-1"
          label="ログインID"
          autocomplete="username"
        />
        <p
          class="text-xxs text-red-300 text-opacity-75 mb-6 ml-1"
          v-text="usernameError"
        />
        <ui-input
          v-model="newPassword"
          type="password"
          class="mb-1"
          label="パスワード"
          autocomplete="new-password"
        />
        <p
          class="text-xxs text-red-300 text-opacity-75 mb-6 ml-1"
          v-text="newPasswordError"
        />
        <ui-input
          v-model="confirmPassword"
          type="password"
          class="mb-1"
          label="パスワード（再入力）"
          autocomplete="new-password"
        />
        <p
          class="text-xxs text-red-300 text-opacity-75 mb-8 ml-1"
          v-text="confirmPasswordError"
        />
        <ui-button
          type="submit"
          color="primary"
          class="w-full"
          :disabled="disabled"
          :loading="submitting"
        >
          登録
        </ui-button>
        <router-link
          tag="button"
          to="/signup"
          class="block focus:outline-none py-3 px-6 mt-4 mx-auto text-sm text-sub"
          replace
        >
          <font-awesome-icon
            class="mr-1"
            :icon="['fas', 'chevron-left']"
          />
          招待コード入力画面に戻る
        </router-link>
      </form>
    </ui-transition>
  </div>
</template>
<script>
export default {
  name: 'Signup',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    ready: false,
    submitting: false,
    displayName: '',
    username: '',
    newPassword: '',
    confirmPassword: '',
  }),
  computed: {
    realname() {
      return this.$store.getters['auth/invitationRealName'];
    },
    disabled() {
      return !this.displayName
          || !this.username
          || !this.newPassword
          || !this.confirmPassword
          || !!this.displayNameError
          || !!this.usernameError
          || !!this.newPasswordError
          || !!this.confirmPasswordError;
    },
    displayNameError() {
      if (!/^[a-zA-Z]*$/.test(this.displayName)) {
        return '表示名はアルファベットのみ使用可能です';
      }
      if (this.displayName.length > 20) {
        return '表示名は20文字以内にする必要があります';
      }
      return null;
    },
    usernameError() {
      if (!/^[\w]*$/.test(this.username)) {
        return 'ログインIDはアルファベット・数字・およびアンダースコア(_)のみ使用可能です。';
      }
      if (this.username.length > 20) {
        return 'ログインIDは20文字以内にする必要があります';
      }
      if (this.username.length < 4 && this.username.length > 0) {
        return 'ログインIDは4文字以上にする必要があります';
      }
      return null;
    },
    newPasswordError() {
      if (!/^[\w!@#$%-]*$/.test(this.newPassword)) {
        return 'パスワードはアルファベット・数字・および一部記号(!@#$%-)のみ使用可能です。';
      }
      if (this.newPassword.length > 32) {
        return 'パスワードは32文字以内にする必要があります';
      }
      if (this.newPassword.length < 8 && this.newPassword.length > 0) {
        return 'パスワードは8文字以上にする必要があります';
      }
      if (
        this.newPassword === ''
        || (/[a-z]/.test(this.newPassword) && /[A-Z]/.test(this.newPassword) && /[0-9]/.test(this.newPassword))
        || (/[a-z]/.test(this.newPassword) && /[0-9]/.test(this.newPassword) && /[_!@#$%-]/.test(this.newPassword))
        || (/[A-Z]/.test(this.newPassword) && /[0-9]/.test(this.newPassword) && /[_!@#$%-]/.test(this.newPassword))
      ) {
        return null;
      }
      return 'パスワードは大文字、小文字、数字、記号いずれか3種類以上を混在させる必要があります。';
    },
    confirmPasswordError() {
      if (this.confirmPassword && this.newPassword !== this.confirmPassword) {
        return 'パスワードが一致しません';
      }
      return null;
    },
  },
  created() {
    if (this.$store.getters['auth/isAuthenticated']) {
      this.$router.replace('/');
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    });
  },
  methods: {
    async signup() {
      this.submitting = true;
      await this.$store.dispatch('auth/signUp', {
        displayName: this.displayName,
        username: this.username,
        password: this.confirmPassword,
      });
      this.submitting = false;
      if (this.$store.getters['auth/hasError']) {
        let message;
        switch (this.$store.getters['auth/error']) {
          case 'Username is already in use':
            message = '既に使われているユーザー名です';
            break;
          case 'Password is weak':
            message = '入力されたパスワードはセキュリティ上の理由で使用できません';
            break;
          default:
            message = '不正な画面遷移を検出したため、お手数ですが招待コード入力からやり直してください。';
            break;
        }
        this.$store.dispatch('notification/set', {
          type: 'error',
          message,
          timeout: 3000,
        });
      } else {
        this.$store.dispatch('notification/set', {
          type: 'success',
          message: 'ユーザー登録が完了しました！',
        });
        this.$router.replace('/');
      }
    },
  },
};
</script>

<style lang="sass">
  .signup-form__logo
    background-image: url('../assets/tfms-logo.png')
</style>
