<template>
  <div class="w-full">
    <div class="input-invitation-code-form__logo h-36 mx-auto mb-6 bg-contain bg-no-repeat bg-center" />
    <ui-transition>
      <form
        v-if="ready"
        @submit.prevent="submit"
      >
        <ui-input
          v-model="invitationCode"
          label="招待コードを入力"
          class="mb-6"
        />
        <ui-button
          type="submit"
          color="primary"
          class="w-full"
          :disabled="disabled"
          :loading="submitting"
        >
          送信
        </ui-button>
        <router-link
          tag="button"
          to="/"
          class="block focus:outline-none py-3 px-6 mt-4 mx-auto text-sm text-sub"
          replace
        >
          <font-awesome-icon
            class="mr-1"
            :icon="['fas', 'chevron-left']"
          />
          ログインに戻る
        </router-link>
      </form>
    </ui-transition>
  </div>
</template>
<script>
export default {
  name: 'InputInvitationCode',
  data: () => ({
    ready: false,
    invitationCode: '',
    submitting: false,
  }),
  computed: {
    disabled() {
      return !this.invitationCode;
    },
  },
  created() {
    if (this.$store.getters['auth/isAuthenticated']) {
      this.$router.replace('/');
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    });
  },
  methods: {
    async submit() {
      this.submitting = true;
      await this.$store.dispatch('auth/verifyInvitationCode', this.invitationCode);
      this.submitting = false;
      if (this.$store.getters['auth/hasError']) {
        let message;
        switch (this.$store.getters['auth/error']) {
          case 'Not found':
            message = '招待コードが見つかりません';
            break;
          case 'Expired':
            message = '招待コードの期限が切れています';
            break;
          default:
            message = '招待コードが不正です';
            break;
        }
        this.$store.dispatch('notification/set', {
          type: 'error',
          message,
        });
      } else {
        this.$router.replace(`/signup/${this.invitationCode}`);
      }
    },
  },
};
</script>

<style lang="sass">
  .input-invitation-code-form__logo
    background-image: url('../assets/tfms-logo.png')
</style>
